import React from 'react';
import personalDetailsImage from '../../assets/images/ui-personal-data.png';

const PersonalDetailsContent = () => (
    <div>
        <h1 class="text-2xl font-bold text-gray-800 mb-4">Personal Details Page Documentation</h1>

        <section class="mb-6">
            <h2 class="text-xl font-semibold text-gray-700">Overview</h2>
            <p class="mt-2 text-gray-600">
                This page provides a detailed layout of an employee's personal information. It is divided into two sections: the profile section on the left and the details section on the right. The layout is styled using Tailwind CSS for a responsive and modern design.
            </p>
        </section>

        <section class="mb-6">
            <h2 class="text-xl font-semibold text-gray-700">Profile Section</h2>
            <p class="mt-2 text-gray-600">
                The left section displays the employee's profile picture, full name, and a button to upload a new picture. The profile picture is styled as a circular image with a shadow, ensuring a clean and professional look.
            </p>
            <ul class="list-disc pl-5 mt-2 text-gray-600">
                <li>Profile picture: Circular image with a shadow.</li>
                <li>Full name: Displayed in a bold and large font.</li>
                <li>Upload button: A blue button with hover effects for better user interaction.</li>
            </ul>
        </section>

        <section class="mb-6">
            <h2 class="text-xl font-semibold text-gray-700">Details Section</h2>
            <p class="mt-2 text-gray-600">
                The right section contains the employee's personal and professional details, including first name, surname, email, department, employee ID, phone number, and pay rate. These details are presented in a two-column grid layout for optimal readability.
            </p>
            <ul class="list-disc pl-5 mt-2 text-gray-600">
                <li>Each detail is labeled with a small, muted font.</li>
                <li>Values are displayed in a larger, bold font for emphasis.</li>
                <li>The section includes an edit button for updating details, styled as a green button with an icon.</li>
            </ul>
        </section>

        <section>
            <h2 class="text-xl font-semibold text-gray-700">Key Features</h2>
            <ul class="list-disc pl-5 mt-2 text-gray-600">
                <li>Responsive design ensures compatibility across devices.</li>
                <li>Hover effects on buttons for improved interactivity.</li>
                <li>Clean and structured layout for easy navigation.</li>
                <li>Editable details section for user updates.</li>
            </ul>
        </section>
        <img src={personalDetailsImage} alt="Image of the employee portal personal details feature" />
        <p>

        </p>
    </div>
);

export default PersonalDetailsContent;