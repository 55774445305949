import React from 'react';

const ReportsContent = () => (
    <div>
        <p>The reports will give you a set of data that you can look at to improve your performance, like:</p>
        <ul>
            <li>Average worked hours vs team worked hours</li>
            <li>Average working day start and end hours vs team ones</li>
            <li>Completed tasks vs team completed tasks</li>
            <li>Number of outstanding tasks / overdue tasks</li>
            <li>Number of completed tasks on time vs overdue ones</li>
        </ul>
    </div>
);

export default ReportsContent;