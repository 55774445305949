import React from 'react';
import timeOffImage from '../../assets/images/ui-time-off.png';
import timeOffRequestImage from '../../assets/images/ui-time-off-request.png';

const TimeOffContent = () => (
    <div>
        <p className="my-4">
            The time off page will show on the top part an overview of the vacation status for the current 
            year (accrued and used vacations as well as days left compared to the total number of days per year)
        </p>
        <img src={timeOffImage} alt="Image of the employee portal Time off feature" />
        <p className="my-4">
            From here vacation requests can be booked, which will get either approved or rejected by
            the employee's manager:
        </p>
        <img src={timeOffRequestImage} alt="Image of the employee portal Time off request feature" />
    </div>
);

export default TimeOffContent;