import React from 'react';

const ShiftContent = () => (
    <div>
        <h2>shiftContent</h2>
        <p>The employee portal dashboard provides a quick overview of the employee's work and personal information. It includes the following sections:</p>
        <ul>
            <li>Personal Information: Displays the employee's name, job title, and contact information.</li>
            <li>Work Information: Shows the employee's current work status, such as whether they are on a break or on a task.</li>
            <li>Time Off: Displays the employee's available time off and the number of days they have taken off.</li>
            <li>Tasks: Lists the tasks assigned to the employee, including their status and due date.</li>
            <li>Reports: Provides access to various reports, such as time sheets and performance reviews.</li>
        </ul>
        <p>The dashboard is designed to provide employees with a quick and easy way to access their work information and stay up-to-date on their tasks and time off.</p>
    </div>
);

export default ShiftContent;