import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LayoutSecondary from '../components/layout/LayoutSecondary';

import DashBoardContent from '../components/documentation/dashBoardContent';
import TimeOffContent from '../components/documentation/timeOffContent';
import TasksContent from '../components/documentation/tasksContent';
import ReportsContent from '../components/documentation/reportsContent';
import TimeSheetsContent from '../components/documentation/timeSheetsContent';
import ShiftContent from '../components/documentation/shiftContent';
import PersonalDetailsContent from '../components/documentation/personalDetailsContent';

const paragraphs = [
  {
    name: 'Dashboard',
    title: 'Learn more about the employee portal dashboard',
    content: <DashBoardContent />,
    link: 'dashboard'
  },
  {
    name: 'Time off',
    title: 'Learn more about how to manage vacations / time off in the employee portal',
    content: <TimeOffContent />,
    link: 'vacations'
  },
  {
    name: 'Tasks',
    title: 'Learn more about task management in the employee portal',
    content: <TasksContent />,
    link: 'tasks'
  },
  {
    name: 'Shifts',
    title: 'Learn more about how to manage employee shifts in the employee portal',
    content: <ShiftContent />,
    link: 'shifts'
  },
  {
    name: 'Reports',
    title: 'Learn more about the employee portal available reports',
    content: <ReportsContent />,
    link: 'reports'
  },
  {
    name: 'Time sheets',
    title: 'Learn more about the employee portal time sheets management',
    content: <TimeSheetsContent />,
    link: 'timesheets'
  },
  {
    name: 'Personal details',
    title: 'Learn more about how to manage personal data in the employee portal',
    content: <PersonalDetailsContent />,
    link: 'personaldetails'
  }
];

const Success = () => (
  <LayoutSecondary>
    <section className="pt-20 md:py-15">
      <div className="container mx-auto px-8">
        <h1 className="blocktext-4xl lg:text-3xl xl:text-2xl font-bold leading-none w-full">
          Employee portal documentation
        </h1>
        <div className="text-xl py-5 md:py-20">
          <p className="my-8 font-light">
            The employee portal's aim is to enable each employee, both
            contractors or permanent, to have access and manage their own data, in
            a secure way. The employee portal is available as a web application
            from any device which has a web browser. We recommend using Chrome to access
            the employee portal.</p>
          <p className="my-8 font-light">
            Here you will learn how to use Punch-in employee portal. In detail, the
            following topics will be covered:
            <ul>
              {paragraphs.map(p => (<li>
                <AnchorLink className={`
                  py-4 px-12
                  my-8
                  hover:text-
                  hover:text-blue-700
                  rounded-lg
                  font-bold
                  `}
                  href={`#${p.link}`}
                  title={p.title}>
                  {p.name}
                </AnchorLink>
              </li>))}
            </ul>
          </p>
          {paragraphs.map(p => (
            <section id={p.link} class="p-6 py-24 bg-gray-50 min-h-screen">
              <h2 className="text-3xl font-semibold text-gray-800 mb-6">
                {p.name}
              </h2>
              <div className="my-8 font-light">
                {p.content}
              </div>
            </section>
          ))}

        </div>
      </div>
    </section>
  </LayoutSecondary>
);

export default Success;
