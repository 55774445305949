import React from 'react';
import dashboardImage from '../../assets/images/ui-dashboard.png';

const DashBoardContent = () => (
<div>
  <div className="mb-8">
    <h2 className="text-2xl font-semibold text-gray-700 mb-4">Overview</h2>
    <p className="text-gray-600">
      This dashboard provides an overview of worked hours, accrued hours, earned amount, and attendance details for the current month. 
      The interface includes a bar chart for visualization and a table for detailed attendance records.
      <img src={dashboardImage} alt="Image of the employee portal Dashboard" />
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-xl font-medium text-gray-700 mb-2">1. Bar Chart</h2>
    <div className="bg-white rounded-lg shadow-md p-4">
      <p className="text-gray-600 mb-4">
        The bar chart displays the hours worked on each day of the current month. It provides a visual representation for quick insights into working patterns.
      </p>
      <div className="h-48 bg-gray-200 flex items-center justify-center rounded-lg">
        <span className="text-gray-500">Bar Chart Example</span>
      </div>
    </div>
  </div>

  <div className="mb-8">
    <h2 className="text-xl font-medium text-gray-700 mb-2">2. Month's Accrued Hours and Earnings</h2>
    <div className="bg-white rounded-lg shadow-md p-4">
      <p className="text-gray-600 mb-4">
        This section summarizes the total hours worked, accrued earnings, and the calculated pay for the month based on the total worked hours.
      </p>
      <div className="flex items-center justify-between">
        <div className="text-left">
          <p className="text-gray-800 font-bold text-lg">Accrued Hours</p>
          <p className="text-3xl font-semibold text-gray-800">70.5</p>
        </div>
        <div className="text-right">
          <p className="text-gray-800 font-bold text-lg">Earned Amount</p>
          <p className="text-3xl font-semibold text-gray-800">£3525.00</p>
          <p className="text-sm text-gray-500">Per worked hours this month</p>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h2 className="text-xl font-medium text-gray-700 mb-2">3. Latest Attendance Details</h2>
    <div className="bg-white rounded-lg shadow-md p-4">
      <p className="text-gray-600 mb-4">
        This table provides detailed attendance data, including the date, check-in and check-out times, site location, and total hours worked for each entry.
      </p>
      <table className="table-auto w-full text-left text-gray-800">
        <thead className="bg-gray-100 border-b">
          <tr>
            <th className="py-2 px-4">Date</th>
            <th className="py-2 px-4">In</th>
            <th className="py-2 px-4">Out</th>
            <th className="py-2 px-4">Site</th>
            <th className="py-2 px-4 text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b hover:bg-gray-50">
            <td className="py-2 px-4">11/04/2024</td>
            <td className="py-2 px-4">08:30:00</td>
            <td className="py-2 px-4">17:30:00</td>
            <td className="py-2 px-4">PRU</td>
            <td className="py-2 px-4 text-right">9</td>
          </tr>
          <tr className="border-b hover:bg-gray-50">
            <td className="py-2 px-4">10/04/2024</td>
            <td className="py-2 px-4">08:30:00</td>
            <td className="py-2 px-4">17:00:00</td>
            <td className="py-2 px-4">PRU</td>
            <td className="py-2 px-4 text-right">8.5</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

);

export default DashBoardContent;