import React from 'react';
import timeSheetImage from '../../assets/images/ui-timesheets.png';

const TimeSheetsContent = () => (
    <div>
        <p className="my-4">
            The timesheets page allows for a job description to be entered so that the approver can see
            what has been carried out during that time frame. Time sheets can be attributed to projects and
            tasks.
        </p>
        <img src={timeSheetImage} alt="Employee portal timesheets feature" />
    </div>
);

export default TimeSheetsContent;