import React from 'react';
import taskstImage from '../../assets/images/ui-tasks.png';
import tasksAddCommentImage from '../../assets/images/ui-tasks-add-comment.png';
import tasksAddTaskImage from '../../assets/images/ui-tasks-add.png';
import tasksAddTaskAssigneeImage from '../../assets/images/ui-tasks-add-assignee.png';

const TasksContent = () => (
    <div>
        <p className="my-4">
            There are two tasks pages. One is for tasks assigned to the employee, whereas the other one is
            for tasks that have been created by the employee. The first will show what are the outstanding tasks assigned to the employee.
            New tasks can be created and assigned as well.
            Comments can be added to the currently assigned tasks; status change is also done from here. 
        </p>
        <img src={taskstImage} alt="Employee portal tasks feature" />
        <p className="my-4">
            You can add comments to a task as seen in the image below:
        </p>

        <img src={tasksAddCommentImage} alt="Employee portal tasks feature" />
        <p className="my-4">
            New tasks can be created and assigned as well, by choosing the appropriate fields (due date, assignee, status, description, etc).
            When creating a new task the following fields are available:
            <ul>
                <li><strong>ETC</strong> Estimated completion time, i.e. the expected time to complete the task, in hours</li>
                <li><strong>Assignee</strong> The person responsible for the task</li>
                <li><strong>Title</strong> Estimated completion time, i.e. the expected time to complete the task</li>
                <li><strong>Description</strong> Task description</li>
                <li>
                    <strong>Deadline</strong> Estimated completion date; it differs from the ETC in two ways: 
                    the ETC is expressed in hours and represents the time it takes to fulfill the task, 
                    whereas the Deadline is a date, by which the time is expected to be completed. This accounts for multiple tasks that need completing </li>
            </ul>
        </p>
        <p className="my-4">
            <strong>How to correctly account for tasks and worked hours</strong> Ideally your employees will have 
            daily (or multi-daily) tasks that they have to fulfill. By creating tasks and letting employees manage 
            them by recording time and effort on them, you will be ablee to have better visibility on how busy your
            workforce is, how much tasks are completed on time according to estimates and how many hours has a 
            person spent on each task. This information is all available in the admin as well as  the
            employee dashboards. 
        </p>
    </div>
);

export default TasksContent;